export default {
  common: {
    brand: 'لمونو',
    slogan: 'لمونو، فروشگاه آنلاین تصفیه آب',
  },
  pageTitle: {
    home: 'لمونو - خانه',
    products: 'لمونو - محصولات',
    support: 'لمونو - پشتیبانی',
    news: 'لمونو - اخبار',
    aboutUs: 'لمونو - درباره ما',
    contactUs: 'لمونو - ارتباط با ما',
    purchaseGuide: 'لمونو - راهنما',
    shoppingCart: 'لمونو - سبدخرید',
    loginRegister: 'لمونو - ورود / ثبت‌نام',
  },
  nav: {
    home: 'خانه',
    products: 'محصولات',
    support: 'پشتیبانی',
    news: 'اخبار',
    aboutUs: 'درباره ما',
    contactUs: 'ارتباط با ما',
    purchaseGuide: 'راهنما',
    shoppingCart: 'سبدخرید',
    loginRegister: 'ورود / ثبت‌نام',
  },
  product: {
    addToCart: 'افزودن به سبد',
    categories: {
      purifier: 'تصفیه‌کننده',
      tank: 'مخزن',
      parts: 'قطعات',
      filter: 'فیلتر',
      accessories: 'لوازم جانبی',
    }
  },
  filter: {
    priceMin: 'ارزان‌ترین',
    priceMax: 'گران‌ترین',
    newest: 'جدید‌ترین',
    oldest: 'قدیمی‌ترین',
  },
  footer: {
    social: {
      title: 'ما را در شبکه‌های اجتماعی دنبال کنید'
    },
    about: {
      about: 'درباره ما',
      text: 'شرکت لـمونو از سال 1391 فعالیت خود در زمینه تولید و فروش دستگاه‌های تصفیه‌آب، فیلتر و قطعات جانبی را در ایران آغاز نموده است. این شرکت با بهره‌مندی از تجارب پیشین خود در عرصه دستگاه های تصفیه آب خانگی و همچنین پشتیبانی و ارائه خدمات پس از فروش از برند لمون را آغاز کرده به صورت دائم ارائه می‌دهد. همواره امیدوار هستیم تا بتوانیم همراه همیشگی شما در ایران باشیم. اعتبار ما جلب رضایت و اعتماد شماست.'
    },
    links: {
      links: 'لینک‌ها',
      news: 'اخبار و رویدادها',
      videMag: 'مجله ویدیوئی',
      collab: 'همکاری با سازمان ها',
      privacyPolicy: 'حفظ حریم خصوصی',
      orderTracking: 'پیگیری سفارش',
      serviceRequest: 'درخواست ارسال سرویس کار',
      products: 'محصولات',
      aboutUs: 'درباره ما',
      contactUs: 'تماس با ما',
      afterSaleServices: 'خدمات پس از فروش',
      support: 'پشتیبانی',
      faq: 'سؤالات و راهنمای خرید',
    },
    app: {
      downloadApp: 'دانلود اپلیکیشن'
    }
  },
  search: {
    placeholder: "جستجو...",
    sample01: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است",
    sample02: "مداد رنگی ها مشغول بودند به جز مداد سفید.",
    sample03: "سیاهی شب گم شده بودند"
  },
  article: {
    "sampleTitle": "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
    "readMore": "ادامه مطلب..."
  },
  contact: {
    name: 'نام',
    email: 'پست الکترونیک',
    subject: 'عنوان',
    yourMessage: 'پیام شما',
  },
  order: {
    status: {
      initial: '',
      submitted: 'ثبت سفارش',
      payed: 'پرداخت',
      preparing: 'آماده سازی',
      sent: 'ارسال بسته',
      delivering: 'در مسیر',
      delivered: 'تحویل به مشتری',
    }
  },
  serviceRequest: {
    type: {
      install: 'نصب',
      repair: 'تعمیر',
      'check-replacement-parts': 'بررسی و تعمیر قطعات',
    }
  },
  bank: {
    parsian: 'پارسیان'
  }
}
